<template>
  <new-drop-down buttonType="bordered" icon data-cy="queue-menu">
    <div class="flex flex-col items-start justify-center gap-y-1">
      <new-button
        v-for="option in options"
        :key="option.dataCy"
        :data-cy="option.dataCy"
        type="dropdown"
        @click="option.onClick"
      >
        <inline-svg
          :class="`h-5 w-5 stroke-current ${option.additionalClasses}`"
          :src="option.icon"
        />
        <span :class="`${option.additionalClasses}`">{{ option.text }}</span>
      </new-button>
    </div>
  </new-drop-down>
</template>

<script>
  import GetHypefurySubscriptionMixin from '@/views/Mixins/GetHypefurySubscriptionMixin';
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin';
  import MobileMixinVue from '../views/Mixins/MobileMixin.vue';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';
  import controller from '@/controller';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    mixins: [GetHypefurySubscriptionMixin, SwalModalMixin, MobileMixinVue, CustomerStatusMixin],
    name: 'queue-options',
    props: {
      type: {
        type: String,
        default: 'desktop',
      },
    },
    methods: {
      showConditionalRetweetsModal() {
        this.$emit('tweet-booster');
      },
      deleteAllQueuedPosts() {
        this.$emit('delete-all-queued-posts');
      },
      openSetQueuedPostsAsEvergreenPrompt() {
        this.$emit('open-set-queued-posts-as-evergreen-prompt');
      },
      moveAllQueuedPostsToTop() {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp(
            'Move all tweets to top',
            'move all tweets to top',
          );
          return;
        }
        this.$emit('move-all-queued-posts-to-top');
      },
      async enableThreadsCrosspostingForAllQueuedPosts() {
        this.swalModal({
          title: 'Enable Threads cross-posting',
          text: 'Are you sure you want to enable Threads cross-posting for all your  queued posts?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            try {
              await controller.enableThreadsCrosspostingForAllQueuedPosts(
                this.currentUser,
                this.userProfile,
              );
              this.$notify({
                type: 'success',
                message: 'Enabled Threads cross-posting for all your queued posts',
              });
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: 'An error has occurred while enabling Threads cross-posting for all your queued posts',
                type: 'warning',
              });
            }
          }
        });
      },
      async disableThreadsCrosspostingForAllQueuedPosts() {
        this.swalModal({
          title: 'Disable Threads cross-posting',
          text: 'Are you sure you want to disable Threads cross-posting for all your queued posts?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: async () => {
            try {
              await controller.disableThreadsCrosspostingForAllQueuedPosts(
                this.currentUser,
                this.userProfile,
              );
              this.$notify({
                type: 'success',
                message: 'Disabled Threads cross-posting for all your queued posts',
              });
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: 'An error has occurred while disabling Threads cross-posting for all your queued posts',
                type: 'warning',
              });
            }
          }
        });
      },
      shuffleQueue() {
        this.$emit('shuffle-queue');
      },
      openCSVUploadModal() {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp(
            'Upload a CSV file',
            'queue tweets from a CSV file',
          );
          return;
        }
        this.$emit('open-csv-upload-modal');
      },
    },
    data() {
      return {
        options: [
          {
            dataCy: 'queue-set-all-evergreen',
            icon: '/img/icons/star-empty.svg',
            text: 'Set all queued posts as evergreen',
            onClick: this.openSetQueuedPostsAsEvergreenPrompt,
          },
          {
            dataCy: 'queue-shuffle-queue',
            icon: '/img/icons/new-shuffle-icon.svg',
            text: 'Shuffle Queue',
            onClick: this.shuffleQueue,
          },
          {
            dataCy: 'queue-move-to-top',
            icon: '/img/icons/move-to-top-icon.svg',
            text: 'Move all posts to the top',
            onClick: this.moveAllQueuedPostsToTop,
          },
          {
            dataCy: 'enable-threads-cross-posting',
            icon: '/img/icons/new/socials/threads.svg',
            text: 'Enable Threads cross-posting for all queued posts',
            onClick: this.enableThreadsCrosspostingForAllQueuedPosts,
          },
          {
            dataCy: 'disable-threads-cross-posting',
            icon: '/img/icons/new/socials/threads.svg',
            text: 'Disable Threads cross-posting for all queued posts',
            onClick: this.disableThreadsCrosspostingForAllQueuedPosts,
          },
          {
            dataCy: 'queue-delete-all-queued',
            icon: '/img/icons/delete.svg',
            text: 'Delete all queued posts',
            onClick: this.deleteAllQueuedPosts,
            additionalClasses: 'text-red-500',
          },
        ],
      };
    },
  };
</script>
