<template>
  <div>
    <div class="mx-auto w-full">
      <features-reminder
        @open-focus-mode="showPublishModal(null, true)"
        @show-conditional-retweets-modal="showConditionalRetweetsModal"
        @show-autoplug-modal="showAutoplugModal"
      />
      <h1 class="mb-6">Queue</h1>
      <new-tabs tabStyle="bordered" defaultTabHash="queue">
        <template v-slot:actions="{ activeTab }">
          <div class="relative">
            <queue-options
              v-if="activeTab === 'queue'"
              @delete-all-queued-posts="deleteAllQueuedPosts"
              @open-set-queued-posts-as-evergreen-prompt="openSetQueuedPostsAsEvergreenPrompt"
              @move-all-queued-posts-to-top="moveAllQueuedPostsToTop"
              @shuffle-queue="shuffleQueue"
            />
            <portal-target v-else name="addSlot" />
          </div>
        </template>
        <new-tab label="Queue" icon="/img/icons/queue-icon.svg" id="queue">
          <new-alert
            type="warning"
            radius="rounded-lg"
            class="my-4"
            v-show="hasNoFreeAutoDMsLeft"
          >
            You have run out of free Auto-DMs for the week. It will reset in
            {{ freeAutoDMsDaysLeft }} or you can
            <upgrade-to-premium-link class="text-main-color-100"
              >upgrade to {{ getPlanLabel('premium') }}</upgrade-to-premium-link
            >
            for unlimited Auto-DMs.
          </new-alert>

          <new-alert
            v-if="shouldShowSchedulingLimitAlert"
            data-cy="queue-subscription-alert"
            class="my-4"
          >
            <p v-if="userProfile.customerStatus === 'none'">
              You are currently on the {{ userPlan }} plan. You can schedule tweets and threads up
              to {{ userSchedulingLimit.value }} {{ userSchedulingLimit.unit }} ahead but they won't
              be posted<br />
              <a
                @click="openSchedulingLimitNextPlanPopUp"
                href="javascript:;"
                class="text-main-color-100"
                data-cy="queue-upgrade-link"
                >Upgrade</a
              >
              to unlock posting, unlimited scheduling, autoplugs, recurrent promotional posts and
              much more.
            </p>

            <p v-else-if="subscriptionResumesAt">
              Your subscription is paused until {{ subscriptionResumesAt }}. You can schedule tweets
              and threads up to {{ userSchedulingLimit.value }}
              {{ userSchedulingLimit.unit }} ahead.
            </p>

            <p v-else>
              You are currently on the {{ userPlan }} plan. You can schedule tweets and threads up
              to {{ userSchedulingLimit.value }} {{ userSchedulingLimit.unit }} ahead.<br />
              <a
                @click="openSchedulingLimitNextPlanPopUp"
                href="javascript:;"
                class="text-main-color-100"
                data-cy="queue-upgrade-link"
                >Upgrade</a
              >
              to unlock unlimited scheduling, autoplugs, recurrent promotional posts and much more.
            </p>
          </new-alert>

          <new-alert class="my-4" v-show="userRanOutOfDailyDMs">
            You've hit the limit of {{ autoDMLimits.successfulDMs }} Auto-DMs per day.<br />
            We're pausing the sending of DM's for you today to decrease spam signals. <br />
            Do not worry, we will resume sending Auto-DMs tomorrow.
          </new-alert>

          <new-alert class="my-4" v-show="userProfile.customerStatus === 'none' && enabledFeatures">
            One of your scheduled posts uses {{ enabledFeatures }}. Your tweet will be published,
            but the feature will only work when you
            <router-link to="/billing" class="text-main-color-100"
              >grab a {{ getPlanLabel('standard') }} subscription</router-link
            >.
          </new-alert>

          <new-alert class="my-4" v-show="hasExtraEvergreenPostsPerDay">
            We have now limited the number of evergreen slots to 1 per day.<br />
            Please update your
            <router-link to="/settings#schedule" class="text-main-color-100"
              >schedule</router-link
            >
            accordingly.<br />
            Until then, only one evergreen post will be retweeted per day.
          </new-alert>

          <new-alert class="my-4" v-show="showLinkedInUpgradeAlert">
            One of your scheduled posts is a LinkedIn post.<br />
            It won't be published unless you
            <upgrade-to-premium-link class="text-main-color-100"
              >upgrade your subscription to the
              {{ getPlanLabel('standard') }} plan</upgrade-to-premium-link
            >.
          </new-alert>

            <new-alert class="my-4" v-show="showAutoDMUpgradeAlert">
              One of your scheduled posts uses the AutoDM feature.<br />
              DMs won't be sent unless you
              <upgrade-to-premium-link class="text-main-color-100"
              >upgrade your subscription to the
              {{ getPlanLabel('premium') }} plan</upgrade-to-premium-link
              >.
            </new-alert>

          <new-alert class="my-4" v-if="showLinkedInAuthAlert">
            One of your scheduled posts is a LinkedIn post.<br />
            It won't be published unless you
            <router-link to="/settings#linkedin" class="text-main-color-100"
              >re-authenticate with your LinkedIn account.</router-link
            >.
          </new-alert>

          <new-alert class="my-4" v-if="showLinkedInCredentialsExpirationAlert">
            One of your scheduled posts is a LinkedIn post.<br />
            Your LinkedIn credentials expire in {{ linkedInTokenInfo.expiresInDays }} days. To
            prevent any interruptions in your posting to LinkedIn,
            <router-link to="/settings#linkedin" class="text-main-color-100"
              >refresh the credentials for your LinkedIn account</router-link
            >.
          </new-alert>

          <new-alert
            class="my-4"
            v-if="isThereAnyFacebookPostInQueue && !isUserConnectedToFacebookWithPage"
          >
            One of your scheduled posts is a Facebook post.<br />
            It won't be published unless you
            <router-link to="/settings#facebook" class="text-main-color-100"
              >connect your Facebook account to Hypefury</router-link
            >.
          </new-alert>

          <new-alert class="my-4" v-if="isThereAnyTiktokPostInQueue && !isUserConnectedToTiktok">
            One of your scheduled posts is a TikTok post.<br />
            It won't be published unless you
            <router-link to="/settings#tiktok" class="text-main-color-100"
              >connect your TikTok account to Hypefury</router-link
            >.
          </new-alert>
          <new-alert
            class="my-4"
            v-if="isThereAnyInstagramPostInQueue && !isUserConnectedToInstagram"
          >
            One of your scheduled posts is an Instagram post.<br />
            It won't be published unless you
            <router-link to="/settings#instagram" class="text-main-color-100"
              >connect your Instagram account to Hypefury</router-link
            >.
          </new-alert>

          <new-alert class="my-4" v-if="showLinkedInConnectionAlert">
            One of your scheduled posts is a LinkedIn post.<br />
            It won't be published unless you
            <router-link to="/settings#linkedin" class="text-main-color-100"
              >connect your LinkedIn account to Hypefury</router-link
            >.
          </new-alert>

          <new-alert class="my-4" v-if="showLinkedInPermissionsAlert">
            You do not have the required LinkedIn permissions to use the LinkedIn autoplugs
            feature.<br />
            Please update them by
            <router-link to="/settings#linkedin" class="text-main-color-100"
              >disconnecting then reconnecting your LinkedIn account to Hypefury</router-link
            >.
          </new-alert>

          <new-alert
            class="my-4"
            v-show="isThereAnyPostWithAutoDMInQueue && doesUserNotHaveDMPermission"
          >
            One of your scheduled posts is set to send auto-DMs, but Hypefury doesn't have the
            permission to send DMs on your behalf.<br />
            Please click
            <a @click="logout" class="text-main-color-100">here</a>
            to logout, then log back in order to refresh your permissions.
          </new-alert>

          <new-alert v-if="areAllScheduleSlotsEmpty" class="my-4">
            <p>
              No scheduled slots found. To continue posting, please
              <router-link to="queue#schedule" class="text-main-color-100"
                >create some slots</router-link
              >.
            </p>
          </new-alert>

          <div class="space-y-8">
            <div
              data-cy="queue-day-container"
              :key="midnight"
              v-for="(threads, midnight, index) in postsByDate"
            >
              <div v-if="threads.length > 0">
                <new-queue-day-header
                  :userTimezone="userProfile.timezone"
                  :midnight="midnight"
                  :isFirst="index === 0"
                />

                <queue-slot
                  :postTime="thread.time.toISOString()"
                  :userTimezone="userProfile.timezone"
                  :post="isPostEmptySlot(thread) ? null : thread"
                  :draggedPost="draggedThread"
                  :customerStatus="userProfile.customerStatus"
                  :key="thread.id"
                  v-for="(thread, index) in threads"
                  @open-new-retweet-modal="openNewRetweetModal"
                  @dragged-thread-updated="
                    (thread) => {
                      draggedThread = thread;
                    }
                  "
                  @dropped-empty-slot="dropEmptySlot"
                  @dropped-non-empty-slot="dropNonEmptySlot"
                  @post-now="postNow"
                  :index="index"
                  :previousThread="getPreviousTime(index, threads)"
                  :indicesOfEmptySlots="indicesOfEmptySlots(threads)"
                />
              </div>
            </div>
          </div>
        </new-tab>
        <new-tab label="Schedule" icon="/img/icons/schedule-icon.svg" id="schedule">
          <new-schedule />
        </new-tab>
      </new-tabs>
    </div>

    <new-retweet-modal
      :timeFromParent="timeForModal"
      @close="closeNewRetweetModal"
      v-if="isNewRetweetModalVisible"
    />

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />
  </div>
</template>

<script>
  import controller from '@/controller';
  import dao from '@/dao';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';
  import lodash from 'lodash';
  import moment from 'moment';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters, mapState } from 'vuex';
  import RetweetMixin from './Mixins/RetweetMixin';
  import TimeFormatterMixin from './Mixins/TimeFormatterMixin';
  import ThreadMixin from '@/views/Mixins/ThreadMixin';
  import { EmptySlot } from '@/models/EmptySlot';
  import DeletePostMixin from '@/views/Mixins/DeletePostMixin';
  import MoveAllPostsToTopMixin from '@/views/Mixins/MoveAllPostsToTopMixin.vue';
  import SetAllQueuedPostsAsEverGreenMixin from '@/views/Mixins/SetAllQueuedPostsAsEvergreenMixin.vue';
  import { formatTweet } from '../util/formatTweet';
  import { isBottomOfPage } from '../util/isBottomOfPage';
  import { validateLink } from '@/util/validateLink';
  import QueueSlot from '@/components/QueueSlot';
  import NewQueueDayHeader from '@/components/NewQueueDayHeader';
  import { store } from '@/store';
  import LogoutMixinVue from './Mixins/LogoutMixin.vue';
  import LinkedInMixin from './Mixins/LinkedInMixin.vue';
  import QueueOptions from '../components/QueueOptions.vue';
  import FacebookMixin from './Mixins/FacebookMixin.vue';
  import GlobalSettingsMixin from './Mixins/GlobalSettingsMixin.vue';
  import FeaturesReminder from '@/components/FeaturesReminder';
  import GetHypefurySubscriptionMixin from './Mixins/GetHypefurySubscriptionMixin.vue';
  import InstagramMixin from './Mixins/InstagramMixin.vue';
  import AutoDMMixin from './Mixins/AutoDMMixin.vue';
  import EnableNotificationsMixin from './Mixins/EnableNotificationsMixin.vue';
  import { buildStorageMediaURL } from '@/config';
  import NewSchedule from '@/components/NewSchedule.vue';
  import { downloadFile } from '@/util/downloadFile';
  import NewRetweetModal from '@/views/Modals/NewRetweetModal.vue';
  import { EventBus } from '@/event-bus';
  import NewTab from '@/components/Tabs/NewTab.vue';
  import NewTabs from '@/components/Tabs/NewTabs.vue';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from './Mixins/UpgradeToNextPlanMixin.vue';
  import TiktokMixin from './Mixins/TiktokMixin.vue';
  import { wasUserCreatedBeforePlansChanges } from '@/../functions/src/util/wasUserCreatedBeforePlansChanges';

  export default {
    name: 'queue',
    data() {
      return {
        daysToLoad: 7,
        isFocusModeEnabled: false,
        loadingMorePosts: false,
        queueLoaded: false,
        postsFromCursors: [],
        hoveredTweetSlot: null,
        timeForModal: null,
        threadToEdit: null,
        threadToScrollTo: null,
        linkToRetweet: null,
        status: null,
        wasURLSharedOnce: false,
        draggedThread: null,
        isFetchingAIGeneratedTweetsPrice: false,
        enableHypefuryAITweets: false,
        priceForAIGeneratedTweets: null,
        emptySlots: 0,
        isAITweetsModalVisible: false,
        isAutoplugModalVisible: false,
        isConditionalRetweetsModalVisible: false,
        isSelectTweetsForAIModalVisible: false,
        tweetsForAI: [],
        isNewRetweetModalVisible: false,
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'isWholeScheduleLoaded']),
      areAllScheduleSlotsEmpty() {
        return this.userProfile.schedule.getScheduleForDB().every((slot) => !slot);
      },
      userSchedulingLimit() {
        return this.PLANS[this.userProfile.customerStatus].limits.scheduling;
      },
      shouldShowSchedulingLimitAlert() {
        if (!this.userProfile) return false;

        const managers = lodash.get(this.userProfile, 'managers', []);
        const nextPlan = this.plansAboveCurrentPlan.find((plan) => {
          const planLimits = this.PLANS[plan.name].limits.scheduling;
          return (
            planLimits.unit !== this.userSchedulingLimit.unit ||
            planLimits.value > this.userSchedulingLimit.value
          );
        });

        return (
          !this.didUserSubscribeBeforePlansChanges(this.userProfile) &&
          !managers.length &&
          nextPlan &&
          this.userSchedulingLimit.value !== Infinity
        );
      },
      showLinkedInCredentialsExpirationAlert() {
        return (
          this.isThereAnyLinkedInCrossPostInQueue &&
          this.isUserConnectedToLinkedIn &&
          this.linkedInTokenInfo &&
          !this.linkedInTokenInfo.isExpired &&
          this.linkedInTokenInfo.expiresInDays <= 7
        );
      },
      showLinkedInUpgradeAlert() {
        return (
          this.userProfile.customerStatus === 'none' &&
          this.isThereAnyLinkedInCrossPostInQueue &&
          this.isUserConnectedToLinkedIn
        );
      },
      showAutoDMUpgradeAlert() {
        const isTrialAllowed = false;
        return (
          this.isUserPlanStandardOrBelow(this.userProfile.customerStatus, isTrialAllowed) &&
          this.isThereAnyAutoDMPostInQueue &&
          (this.userProfile.customerStatus !== 'standard' ||
            (this.userProfile.freeWeeklyAutoDMs && this.userProfile.freeWeeklyAutoDMs.value === 0))
        );
      },
      showLinkedInAuthAlert() {
        return (
          this.isThereAnyLinkedInCrossPostInQueue &&
          this.isUserConnectedToLinkedIn &&
          this.linkedInTokenInfo &&
          this.linkedInTokenInfo.isExpired
        );
      },
      showLinkedInConnectionAlert() {
        return (
          (this.isThereAnyLinkedInPostInQueue || this.isThereAnyLinkedInCrossPostInQueue) &&
          !this.isUserConnectedToLinkedIn
        );
      },
      showLinkedInPermissionsAlert() {
        return (
          this.isUserConnectedToLinkedIn &&
          this.isLinkedInAutoplugEnabled &&
          !this.doesUserHaveUpdatedLinkedInPermissions
        );
      },
      postsByDate() {
        if (!this.schedule) return;
        const posts = this.schedule.getThreadsByDate();
        const keys = lodash.take(Object.keys(posts), this.daysToLoad);
        const windowedPosts = {};
        for (const key of keys) {
          if (posts[key].length > 0) {
            windowedPosts[key] = posts[key];
          }
        }
        return windowedPosts;
      },
      hasPosts() {
        if (!this.postsByDate) return;
        return Object.values(this.postsByDate).find((slots) => slots.length > 0);
      },
      enabledFeatures() {
        if (!this.schedule) return false;
        const isThereAnyShareOnInstagramPostInQueue =
          this.schedule.getAllSlots().filter((slot) => slot.shareOnInstagram === true).length > 0;

        const isThereAnyAutoplugPostInQueue =
          this.schedule
            .getAllSlots()
            .filter((slot) => lodash.get(slot, 'autoplug.status', null) !== null).length > 0;

        const features = {
          'Share on IG': isThereAnyShareOnInstagramPostInQueue,
          Autoplug: isThereAnyAutoplugPostInQueue,
          AutoDM: this.isThereAnyAutoDMPostInQueue,
          LinkedIn: this.isThereAnyLinkedInCrossPostInQueue,
          Facebook: this.isThereAnyFacebookPostInQueue,
        };

        const enabledFeatures = Object.keys(lodash.omitBy(features, (elem) => elem === false));

        if (enabledFeatures.length === 1) {
          return `${enabledFeatures[0]}${
            ['LinkedIn', 'Facebook'].includes(lodash.last(enabledFeatures)) ? ' crossposting' : ''
          } feature`;
        } else if (enabledFeatures.length > 1) {
          return `${enabledFeatures
            .filter((feature) => feature !== lodash.last(enabledFeatures))
            .join(', ')} and ${lodash.last(enabledFeatures)}${
            ['LinkedIn', 'Facebook'].includes(lodash.last(enabledFeatures)) ? ' crossposting' : ''
          } features`;
        } else {
          return null;
        }
      },
      isThereAnyAutoDMPostInQueue() {
        if (!this.schedule) return false;

        return (
          this.schedule
            .getAllSlots()
            .filter((slot) => lodash.get(slot, 'autoDM.enabled', false) !== false).length > 0
        );
      },
      isThereAnyLinkedInCrossPostInQueue() {
        if (!this.schedule) return false;
        const postsForLinkedIn = this.schedule
          .getAllSlots()
          .filter(
            (slot) =>
              slot.linkedIn &&
              !lodash.isNil(slot.linkedIn.text) &&
              slot.linkedIn.postType !== 'carousel'
          );
        return postsForLinkedIn.length > 0;
      },
      isThereAnyLinkedInCarouselPostInQueue() {
        if (!this.schedule) return false;
        const postsForLinkedIn = this.schedule
          .getAllSlots()
          .filter(
            (slot) =>
              slot.linkedIn && slot.linkedIn.text !== null && slot.linkedIn.postType === 'carousel'
          );
        return postsForLinkedIn.length > 0;
      },
      isThereAnyFacebookPostInQueue() {
        if (!this.schedule) return false;
        const postsForFacebook = this.schedule
          .getAllSlots()
          .filter(
            (slot) =>
              (slot.facebook && slot.facebook.text !== null) || slot.source === 'facebook-post'
          );
        return postsForFacebook.length > 0;
      },
      isThereAnyInstagramPostInQueue() {
        if (!this.schedule) return false;
        const instagramPosts = this.schedule
          .getAllSlots()
          .filter((slot) => slot.source === 'instagram-post');
        return instagramPosts.length > 0;
      },
      isThereAnyLinkedInPostInQueue() {
        if (!this.schedule) return false;
        const linkedinPosts = this.schedule
          .getAllSlots()
          .filter((slot) => slot.source === 'linkedin-post');
        return linkedinPosts.length > 0;
      },
      isThereAnyTiktokPostInQueue() {
        if (!this.schedule) return false;
        const tikTokPosts = this.schedule
          .getAllSlots()
          .filter((slot) => slot.source === 'tiktok-post');
        return tikTokPosts.length > 0;
      },
      isThereAnyPostWithAutoDMInQueue() {
        if (!this.schedule) return false;
        const postsWithAutoDM = this.schedule
          .getAllSlots()
          .filter((slot) => slot.autoDM && slot.autoDM.enabled);
        return postsWithAutoDM.length > 0;
      },
      autoRetweetsTooltipContent() {
        return this.areConditionalRetweetsEnabled
          ? 'Tweet booster settings'
          : 'Tweet booster is not enabled';
      },
      autoplugsTooltipContent() {
        return this.isAutoplugEnabled ? 'Autoplugs settings' : 'Autoplugs are not enabled';
      },
      totalScheduledAIPosts() {
        return lodash.get(this.userProfile, 'totalScheduledAIPosts', 0);
      },
      subscriptionResumesAt() {
        if (!this.userProfile.subscriptionResumesAt) return null;
        const browserLocale = require('browser-locale')();
        moment.locale(browserLocale);
        return moment
          .tz(this.userProfile.subscriptionResumesAt.toDate(), this.userProfile.timezone)
          .format('L');
      },
      aiTweetsStatus() {
        if (this.totalScheduledAIPosts >= 100) {
          return {
            enabled: false,
            message: 'You have reached the limit of 100 scheduled tweets per month.',
          };
        } else if (this.isUserPlanStandardOrBelow(this.userProfile.customerStatus)) {
          return {
            enabled: false,
            message: `${this.getPlanLabel('premium')} feature only.`,
          };
        } else {
          return {
            enabled: true,
          };
        }
      },
      hasExtraEvergreenPostsPerDay() {
        return this.userProfile.schedule.getScheduleForDB().some((day) => {
          return day.split('evergreen').length > 2;
        });
      },
      hasNoFreeAutoDMsLeft() {
        return (
          this.userProfile.customerStatus === 'standard' &&
          this.userProfile.freeWeeklyAutoDMs &&
          this.userProfile.freeWeeklyAutoDMs.value === 0 &&
          this.isThereAnyAutoDMPostInQueue
        );
      },
    },
    async mounted() {
      this.$eventStore.visitQueuePage();
      if (this.isUserPlanBasicOrAbove(this.userProfile.customerStatus)) {
        this.onScroll();
      }
      this.fetchScheduleIfItsNull();
      this.isFetchingAIGeneratedTweetsPrice = true;
      Promise.resolve('$2').then((price) => {
        this.isFetchingAIGeneratedTweetsPrice = false;
        this.priceForAIGeneratedTweets = price;
      });
      if (!localStorage.getItem('aiTweetsUseCount')) {
        localStorage.setItem('aiTweetsUseCount', 0);
      }

      if (localStorage.getItem('threads_from_login_page')) {
        this.saveThreadsFromLocalStorageToFirestore();
      }

      if (this.$route.query.carousel) {
        try {
          const fileName = `${this.$route.query.carousel}.pdf`;
          await downloadFile(buildStorageMediaURL(`carousel/${fileName}`), fileName);
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while trying to download your LinkedIn carousel.',
            type: 'warning',
          });
        }
      }

      if (this.isWholeScheduleLoaded) {
        this.syncCurrentlyScheduledPostsCount();
      }
    },
    methods: {
      wasUserCreatedBeforePlansChanges,
      scrollToThread(id) {
        this.threadToScrollTo = id;
      },
      draggedThreadUpdated(thread) {
        this.draggedThread = thread;
      },
      addSlot() {
        this.showSlot = true;
      },
      formatTweet(status) {
        return formatTweet(status);
      },
      dropEmptySlot(slotTime) {
        this.schedule.changeTimeOfThread(this.draggedThread, slotTime);
        this.draggedThread.updateTime(this.currentUser, this.userProfile.uid, slotTime);
      },
      async dropNonEmptySlot(slot) {
        const time1 = moment.tz(slot.time, this.userProfile.timezone).toDate();
        const time2 = moment.tz(this.draggedThread.time, this.userProfile.timezone).toDate();
        await dao.posts.switchPostsTime(slot, this.draggedThread, time1, time2);
      },
      fetchScheduleIfItsNull() {
        if (this.schedule) return;

        store.dispatch('fetchSchedule');
      },
      indicesOfEmptySlots(threads) {
        return threads
          .map((slot, index) => (slot.slotType === 'empty' ? index : -1))
          .filter((index) => index >= 0);
      },
      getPreviousTime(index, threads) {
        return threads[index - 1];
      },
      isPostEmptySlot(post) {
        return post instanceof EmptySlot;
      },
      scheduledRetweetMessage(retweet) {
        const postingTime = retweet.postingTimeData(this.userProfile.timezone);
        let message = `${postingTime.day} at ${postingTime.time}`;
        if (retweet.conditionalRetweetsConditions) {
          const { minRetweetsThreshold, minFavoritesThreshold } =
            retweet.conditionalRetweetsConditions;
          const minRetweetsWording = `${minRetweetsThreshold} ${
            minRetweetsThreshold === 1 ? 'retweet' : 'retweets'
          }`;
          const minFavoritesWording = `${minFavoritesThreshold} ${
            minFavoritesThreshold === 1 ? 'like' : 'likes'
          }`;
          if (minRetweetsThreshold >= 1) {
            message += ` if it gets ${minRetweetsWording}`;
          }
          if (minFavoritesThreshold >= 1) {
            message +=
              minRetweetsThreshold >= 1
                ? ` and ${minFavoritesWording}`
                : ` if it gets ${minFavoritesWording}`;
          }
        }
        return message;
      },
      loadMorePosts: lodash.debounce(
        function (numberOfDaysToLoad) {
          this.daysToLoad += numberOfDaysToLoad ? numberOfDaysToLoad : 7;
        },
        2000,
        { leading: true }
      ),
      showPublishModal(time, isFocusModeEnabled, status) {
        EventBus.$emit('open-composer', time, isFocusModeEnabled, status);
      },
      moveAllQueuedPostsToTop() {
        this.swalModal({
          title: 'Move all your scheduled tweets to the top',
          html:
            `This will move all your queued tweets to the top of the queue, ` +
            `effectively getting rid of all the gaps in your schedule.`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, go ahead',
          preConfirm: () => this.moveAllPostsToTop(),
        });
      },
      onScroll() {
        const that = this;
        window.onscroll = lodash.debounce(() => {
          if (isBottomOfPage() && !that.loadingMorePosts) {
            that.loadMorePosts();
          }
        }, 200);
      },
      openCSVUploadModal() {
        this.isCSVUploadModalVisible = true;
      },
      openNewRetweetModal(time) {
        this.timeForModal = time;
        this.isNewRetweetModalVisible = true;
      },
      closeNewRetweetModal() {
        this.timeForModal = null;
        this.threadToEdit = null;
        this.isNewRetweetModalVisible = false;
      },
      openRetweetModal: function (time) {
        this.timeForModal = time;
        this.showRetweetModal();
      },
      shuffleQueue() {
        controller
          .shuffleQueue(this.currentUser, this.userProfile)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Your scheduled posts have been shuffled!',
            });
          })
          .catch((error) => {
            console.error(error);
            this.swalModal({
              title: 'Sorry',
              text: 'An unknown error has ocurred while shuffling your tweets.',
              type: 'warning',
            });
          });
      },
      openRetweetModalIfURLShared() {
        if (this.wasURLSharedOnce) return;
        const url = new URL(window.location);
        const sharedContent = url.searchParams.get('sharedContent');
        if (!sharedContent) return;
        const { link, isValid } = validateLink(sharedContent);
        if (!isValid) return;
        this.linkToRetweet = link;
        const timeForModal = this.schedule.getNextTimeSlot();
        this.openRetweetModal(timeForModal);
        this.wasURLSharedOnce = true;
      },
      async saveThreadsFromLocalStorageToFirestore() {
        const storedThreads = JSON.parse(localStorage.getItem('threads_from_login_page'));
        const threadsToSave = storedThreads.map((thread) => {
          delete thread.midnight;
          return {
            ...thread,
            time: moment.tz(thread.time, this.userProfile.timezone).toDate(),
          };
        });
        await dao.posts.saveThreadsToFirestore(threadsToSave, this.userProfile.uid);
        this.removeThreadsFromLocalStorage();
      },
      setTweetsForAITweetsModal(tweets) {
        this.tweetsForAI = tweets;
        this.openAITweetsModal();
      },
      sendToPostingSchedule() {
        this.$router.push('/settings#posting-schedule');
      },
      syncCurrentlyScheduledPostsCount() {
        const slots = Object.values(this.schedule.getThreadsByDate()).flat();
        const posts = slots.filter((slot) => slot.type === 'post');

        if (posts.length !== lodash.get(this.userProfile, 'currentlyScheduledPostsCount', 0)) {
          return dao.userProfile.updateScheduledPostsCount(this.userProfile.uid, posts.length);
        }
      },
    },
    components: {
      NewTab,
      NewTabs,
      NewQueueDayHeader,
      QueueSlot,
      QueueOptions,
      FeaturesReminder,
      NewRetweetModal,
      NewSchedule,
      UpgradeToNextPlanPopUp,
    },
    mixins: [
      DeletePostMixin,
      RetweetMixin,
      TimeFormatterMixin,
      MoveAllPostsToTopMixin,
      ThreadMixin,
      SetAllQueuedPostsAsEverGreenMixin,
      LinkedInMixin,
      SwalModalMixin,
      FacebookMixin,
      GlobalSettingsMixin,
      GetHypefurySubscriptionMixin,
      InstagramMixin,
      LogoutMixinVue,
      AutoDMMixin,
      MobileMixin,
      EnableNotificationsMixin,
      CustomerStatusMixin,
      UpgradeToNextPlanMixin,
      TiktokMixin,
    ],
    watch: {
      isWholeScheduleLoaded(val, old) {
        if (!old && val) {
          this.syncCurrentlyScheduledPostsCount();
        }
      },
      schedule(val, old) {
        if (!old || !old.getNextTimeSlot() || val.getNextTimeSlot()) {
          // todo: this is probably broken
          this.openRetweetModalIfURLShared();
        }
      },
      postsByDate(val) {
        const loadedPosts = Object.values(val);

        // If the user has less than 7 slots for the next 7 days,
        // he won't be able to scroll in order to generate more posts.
        // so we generate 7 slots for him in order to fill the page.
        if (loadedPosts.length > 0 && loadedPosts.flat().length < 7) {
          this.loadMorePosts();
        }
      },
    },
  };
</script>

<style>
  .focus-mode-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: white;
  }
  .mode--dark .focus-mode-overlay {
    background-color: rgb(55, 55, 55);
  }
  .remove-icon svg {
    display: none;
  }
</style>
