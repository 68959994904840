var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-modal',{attrs:{"loading":_vm.isSubmitting},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"rounded-3xl p-8 sm:w-591"},[_c('div',{staticClass:"grid gap-6"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('div',{staticClass:"flex h-12 w-12 items-center justify-center rounded-full bg-main-color-100"},[_c('inline-svg',{staticClass:"h-6 w-6 fill-white",attrs:{"src":"/img/icons/date-icon.svg"}})],1),_c('span',{staticClass:"text-2xl font-bold",attrs:{"data-cy":"add-slot-header"}},[_vm._v("Add a slot")])]),_c('div',[(!_vm.isSelectedTimeSlotValid)?_c('span',{staticClass:"text-badge-error"},[_vm._v("Slot(s) are already filled for your current selection")]):_vm._e(),_c('span',{staticClass:"app-modal-text mb-2 block text-base font-medium text-gray-80"},[_vm._v("Platform")]),_c('div',{staticClass:"flex items-center gap-4"},[_c('span',{staticClass:"flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white",class:[
              _vm.selectedSocialMedia === 'twitter'
                ? 'bg-main-color-100'
                : 'app-queue-slot-social-media bg-gray-60 text-white',
            ],attrs:{"data-cy":"add-slot-twitter"},on:{"click":function($event){_vm.selectedSocialMedia = 'twitter'}}},[_c('inline-svg',{staticClass:"h-3.5 w-3.5",attrs:{"src":"/img/icons/twitter.svg"}})],1),_c('span',{staticClass:"flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white",class:[
              _vm.selectedSocialMedia === 'linkedin'
                ? 'bg-main-color-100'
                : 'app-queue-slot-social-media bg-gray-60 text-white',
            ],attrs:{"data-cy":"add-slot-linkedin"},on:{"click":function($event){_vm.selectedSocialMedia = 'linkedin'}}},[_c('inline-svg',{staticClass:"h-3.5 w-3.5 fill-current",attrs:{"src":"/img/icons/linkedin-icon.svg"}})],1),_c('span',{staticClass:"flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white",class:[
              _vm.selectedSocialMedia === 'facebook'
                ? 'bg-main-color-100'
                : 'app-queue-slot-social-media bg-gray-60 text-white',
            ],attrs:{"data-cy":"add-slot-facebook"},on:{"click":function($event){_vm.selectedSocialMedia = 'facebook'}}},[_c('inline-svg',{staticClass:"h-4 w-4 fill-current",attrs:{"src":"/img/icons/facebook-icon.svg"}})],1),_c('span',{staticClass:"flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white",class:[
              _vm.selectedSocialMedia === 'instagram'
                ? 'bg-main-color-100'
                : 'app-queue-slot-social-media bg-gray-60 text-white',
            ],attrs:{"data-cy":"add-slot-instagram"},on:{"click":function($event){_vm.selectedSocialMedia = 'instagram'}}},[_c('inline-svg',{staticClass:"h-4 w-4 fill-current",attrs:{"src":"/img/icons/instagram-icon.svg"}})],1),_c('span',{staticClass:"flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white",class:[
              _vm.selectedSocialMedia === 'threads'
                ? 'bg-main-color-100'
                : 'app-queue-slot-social-media bg-gray-60 text-white',
            ],attrs:{"data-cy":"add-slot-threads"},on:{"click":function($event){_vm.selectedSocialMedia = 'threads'}}},[_c('inline-svg',{staticClass:"h-4 w-4 stroke-current",attrs:{"src":"/img/icons/new/socials/threads.svg"}})],1),_c('span',{staticClass:"flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white",class:[
              _vm.selectedSocialMedia === 'tiktok'
                ? 'bg-main-color-100'
                : 'app-queue-slot-social-media bg-gray-60 text-white',
            ],attrs:{"data-cy":"add-slot-tiktok"},on:{"click":function($event){_vm.selectedSocialMedia = 'tiktok'}}},[_c('inline-svg',{staticClass:"h-4 w-4 fill-current",attrs:{"src":"/img/icons/new/socials/tiktok.svg"}})],1)])]),_c('div',[_c('label',{staticClass:"app-modal-text mb-2 block text-base font-medium text-gray-80",attrs:{"for":"days"}},[_vm._v("Days")]),_c('base-select',{attrs:{"options":_vm.daysOptions,"labelAttribute":"name","valueAttribute":"value","placeholder":"Select an Option","data-cy":"add-slot-day-select"},model:{value:(_vm.newTimeSlotDay),callback:function ($$v) {_vm.newTimeSlotDay=$$v},expression:"newTimeSlotDay"}})],1),_c('div',[_c('label',{staticClass:"app-modal-text mb-2 block text-base font-medium text-gray-80",attrs:{"for":"time"}},[_vm._v("Time")]),_c('base-text-input',{attrs:{"data-cy":"add-slot-time-input","type":"time"},model:{value:(_vm.newTimeSlotTime),callback:function ($$v) {_vm.newTimeSlotTime=$$v},expression:"newTimeSlotTime"}})],1),_c('div',{staticClass:"flex items-center justify-between"},[_c('new-button',{staticClass:"text-main-color-30",attrs:{"type":"light","data-cy":"add-slot-close"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]),_c('new-button',{attrs:{"data-cy":"add-slot-save","disabled":!_vm.isSelectedTimeSlotValid},on:{"click":_vm.addTimeSlot}},[_vm._v(" Save ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }